import React, { useContext } from "react"
import { View } from "react-native"

import Form from "../components/Form"
import { AuthContext } from "../providers/AuthProvider"

const ForgotScreen = () => {
  const { api } = useContext(AuthContext)
  
  const forgotPassword = data => {
    return api.post('forgot-password', data)
  }
  return <View style={{
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }}>
    <View style={{
      width: "100%",
      maxWidth: 340,
      marginHorizontal: 20
    }}>
      <Form fields={[
        {
          label: 'Email',
          name: 'email',
          type: 'email'
        }
      ]} onSubmit={forgotPassword} />
    </View>
  </View>
}

export default ForgotScreen
import React, { useContext } from "react"
import { Text } from "react-native"

import { Styles } from "../Styles"
import BaseScreen from "../components/BaseScreen"
import ScrollList from "../components/ScrollList"
import Update from "../components/Update"
import { AuthContext } from "../providers/AuthProvider"

const ShareScreen = props => {
  const { navigation } = props
  const { user } = useContext(AuthContext)
  
  let published
  return <BaseScreen>
    <Text style={Styles.headingWithPadding}>Share</Text>
    {user ? <ScrollList route={"updates/"+user.slug+"?includeDrafts=true"} itemsSort={(a, b) => {
      const aPublished = a.published_at !== undefined
      const bPublished = b.published_at !== undefined
      if (aPublished != bPublished) {
        return aPublished ? 1 : -1
      } else if (aPublished) {
        return new Date(b.published_at) - new Date(a.published_at)
      } else {
        return new Date(b.created_at) - new Date(a.created_at)
      }
    }} renderItem={(update, addItems) => {
      const content = <Update user={user} content={update} updateItems={addItems} />
      const isPublished = update.published_at !== undefined
      if (isPublished !== published) {
        published = isPublished
        return <>
          <Text style={{ fontWeight: "bold"}}>{published ? "Shared" : "Drafts"}</Text>
          {content}
        </>
      } else {
        return content
      }
    }} /> : null}
  </BaseScreen>
}

export default ShareScreen
import React, { useContext } from "react"
import { NavigationContainer } from "@react-navigation/native"

import { isDesktop } from "./MediaQueries"
import { AuthContext, web } from "./providers/AuthProvider"

import AppStack from "./stacks/AppStack"
import AuthStack from "./stacks/AuthStack"


const linking = {
  config: {
    screens: {
      Home: "/",
      Login: "/login",
      Forgot: "/forgot",
      Reset: "/resetpassword",
      Register: "/register",
      Profile: "/profile/:slug",
      Update: "/profile/:slug/:id",
      PartnerAsk: "/partners/ask",
      PartnerEnter: "/partners/enter",
      EditLurker: "/emailonly/:email",
      EditProfile: "/editprofile",
      Settings: "/settings",
      Help: "/help",
      Privacy: "/privacy",
      About: "/about",
      Updates: "/updates",
      EditUpdate: "/updates/share/:id?",
      GospelWorkers: "/gospelworkers",
      Partners: "/partners/:slug?",
      Share: "/share",
      Messages: "/messages/:slug?"
    }
  }
}

const Router = () => {
  const { isLoggedIn } = useContext(AuthContext)
  return <NavigationContainer linking={linking}>
    {isLoggedIn ? <AppStack /> : <AuthStack />}
  </NavigationContainer>
}

export default Router
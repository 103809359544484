import React, { useEffect, useState } from "react"
import { Alert } from "react-native"
import DeviceInfo from "react-native-device-info"
import axios from "axios"

export const web = typeof process === "undefined"
let Keychain
let PushNotification
if (!web) {
  Keychain = require("react-native-keychain")
  PushNotification = require("react-native-push-notification")
}

const dev = web &&  window.location.host == "partner.dev"
const api_host = "https://api."+(dev ? "partner.dev" : "partnersite.co")
export const storage_url = image => api_host+image.replace(/^public\//, "/storage/")

const hasCookie = () => {
  if (web) {
    return document.cookie.split(";").find(cookie => cookie.startsWith("XSRF-TOKEN=") && cookie !== "XSRF-TOKEN=") !== undefined
  }
}

export const AuthContext = React.createContext()

let interval
export const AuthProvider = ({children}) => {
  const api = {}
  const [isLoggedIn, setIsLoggedIn] = useState()
  const [token, setToken] = useState()
  const [user, setUser] = useState()

  const refreshUser = () => {
    return api.get("user").then(setUser)
  }
  const setupUser = user => {
    setIsLoggedIn(true)
    
    let promise
    if (user && !user.error) {
      setUser(user)
      promise = Promise.resolve()
    } else {
      promise = refreshUser()
    }
    
    if (web && window.location.search.startsWith('?follow=')) {
      const hash = window.location.search.split('?follow=')[1].split('&')
      console.log({hash})
      
      window.history.replaceState({}, document.title, "/")
    }
    return promise
  }
  
  useEffect(() => {
    if (web) {
      if (hasCookie()) {
        setupUser().catch(() => {})
      }
    } else {
      Keychain.getInternetCredentials("token").then(value => {
        if (value) {
          setToken(value.password)
          setupUser().catch(() => {})
        }
      })
    }
  }, [])

  const host = dev ? "partner.dev" : "partnersite.co"
  const axiosInstance = axios.create({
    baseURL: api_host,
    withCredentials: true
  })

  const ensureCookieIsPresent = () => {
    if (web && !hasCookie()) {
      return axiosInstance.get("sanctum/csrf-cookie")
    }
    return Promise.resolve()
  }
  const call = (method, url, data) => {
    if (data) {
      if (method == "get") {
        data = {params: data}
      } else if (data && Object.values(data).some(value => value instanceof File)) {
        if (method == "put") {
          method = "post"
          data._method = "PUT"
        }
        const formData = new FormData()
        Object.keys(data).forEach(k => {
          formData.append(k, data[k])
        })
        data = formData
      }
    }
    return axiosInstance[method](url, data).then(response => response.data).catch(error => {
      if (error.response) {
        const result = error.response.data
        result.status = error.response.status
        throw result
      } else {
        throw error
      }
    }).finally(() => {
      if (interval) {
        clearTimeout(interval)
      }
      if (isLoggedIn) {
        interval = setTimeout(() => {
          api.put("heartbeat")
        }, 30*1000)
      }
    })
  }
  ["get", "post", "put", "delete"].forEach(method => {
    api[method] = (url, data) => {
      return ensureCookieIsPresent().then(() => {
        return call(method, url, data)
      })
    }
  })
  
  const setupToken = data => {
    return DeviceInfo.getDeviceName().then(device_name => {
      data.device_name = device_name
      // token should be send to server and stored against user
      // for User::routeNotificationForFcm to return
      // and cleared when logging out
      //data.fcm_token =
      PushNotification.requestPermissions().then(response => {
        // Object.keys(response).join(",") - sound,alert,notificationCenter,authorizationStatus,lockScreen,badge,critical
        if (response && response.alert) {
          // Allow
          return
        }
        // Decline
      })
      return call("post", "token", data)
    }).then(response => {
      setToken(response.token)
      return Keychain.setInternetCredentials("token", "token", response.token).then(() => {
        return setupUser(response)
      })
    })
  }
  api.register = data => {
    if (web) {
      return api.post("register", data).then(setupUser)
    } else {
      return api.post("register", data).then(() => {
        return setupToken({
          email: data.email,
          password: data.password
        })
      })
    }
  }
  api.login = data => {
    if (web) {
      return api.post("login", data).then(setupUser)
    } else {
      return setupToken(data)
    }
  }
  api.logout = unauthorized => {
    setIsLoggedIn(false)
    if (web) {
      if (hasCookie()) {
        document.cookie = "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=."+host
      }
      if (!unauthorized) {
        api.post("logout").catch(() => {})
      }
    } else {
      if (!unauthorized) {
        axiosInstance.delete("token").catch(() => {})
      }
      Keychain.resetInternetCredentials("token")
    }
  }
  
  axiosInstance.interceptors.request.use(config => {
    if (!web && token) {
      config.headers.Authorization = "Bearer "+token
    }
    return config
  }, error => {
    return Promise.reject(error)
  })
  axiosInstance.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status == 401) {
      api.logout(true)
    }
    return Promise.reject(error)
  })
  
  return <AuthContext.Provider value={{isLoggedIn, api, user, refreshUser}}>
    {children}
  </AuthContext.Provider>
}
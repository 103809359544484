import React, { useContext } from "react"
import { View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import Form from "../components/Form"
import { AuthContext } from "../providers/AuthProvider"

const ResetScreen = props => {
  const { route } = props
  const { api } = useContext(AuthContext)

  const reset = data => {
    data.token = route.params.token
    return api.post('reset-password', data)
  }
  return <View style={{
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }}>
    <View style={{
      width: "100%",
      maxWidth: 340,
      marginHorizontal: 20
    }}>
      <Form fields={[
        {
          label: 'Email',
          name: 'email',
          type: 'email'
        }, {
          label: 'Password',
          name: 'password',
          type: 'password'
        }, {
          label: 'Confirm Password',
          name: 'password_confirmation',
          type: 'password'
        }
      ]} onSubmit={reset} />
    </View>
  </View>
}

export default ResetScreen
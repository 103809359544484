import React, { useContext } from "react"
import { Image } from "react-native"
const profileImage = require("../images/profileImage.png")

import { storage_url } from "../providers/AuthProvider"

const ProfileImage = ({ user, size }) => {
  const source = (user && user.profile_image) ? storage_url(user.profile_image) : profileImage
  return <Image source={source} style={{
    width: size,
    height: size
  }} />
}

export default ProfileImage
import React, { useContext, useState } from "react"
import { Text, View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import Form from "../components/Form"
import { AuthContext } from "../providers/AuthProvider"

const HomeScreen = () => {
  const { api } = useContext(AuthContext)
  const [ data, setData ] = useState()
  const [ firstStageResponse, setFirstStageResponse ] = useState()

  return <View style={{
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }}>
    <View style={{
      width: "100%",
      maxWidth: 340,
      marginHorizontal: 20
    }}>
      <Text>{!data ? "Let's begin with some details" : "A few more details"}</Text>
      <Form fields={!data ? [
        {
          label: "First Name",
          name: "first_name"
        }, {
          label: "Last Name",
          name: "last_name"
        }, {
          label: "Email",
          name: "email",
          type: "email"
        }, {
          label: "Password",
          name: "password",
          type: "password"
        }
      ] : [
        {
          label: "Role",
          name: "role",
          type: "radio",
          options: [
            {
              title: "Gospel Worker",
              value: "gospelworker"
            }, {
              title: "Partner",
              value: "partner"
            }
          ]
        }, {
          label: "Location",
          name: "location"
        }
      ]} submit={!data ? "Next" : "Let's go!"} onSubmit={stageData => {
        if (!data) {
          setData(stageData)
        } else {
          return api.register({...data, ...stageData}).then(() => {
            return {unmount: true}
          }).catch(response => {
            const errors = response.errors
            if (errors && (errors.first_name || errors.last_name || errors.email || errors.password)) {
              setData()
            }
            throw response
          })
        }
      }} />
    </View>
  </View>
}

export default HomeScreen
import React, { useContext, useEffect, useState } from "react"
import { Text, View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import { AuthContext } from "../providers/AuthProvider"
import { Styles } from "../Styles"
import Form from "../components/Form"
import FormError from "../components/FormError"

const EditLurkerScreen = props => {
  const { navigation, route } = props
  const { api } = useContext(AuthContext)

  const email = route.params && decodeURIComponent(route.params.email)
  const [ firstName, setFirstName ] = useState()
  const [ lastName, setLastName ] = useState()
  const [ error, setError ] = useState()
  useEffect(() => {
    api.get("lurker/"+email).then(lurker => {
      setFirstName(lurker.first_name)
      setLastName(lurker.last_name)
      navigation.setOptions({
        title: lurker.name
      })
    }).catch(error => {
      if (error.status == 404) {
        setError('Could not find this e-mail only partner')
      }
    })
  }, [email])

  const save = data => {
    return api.put("lurker/"+email, data).then(() => {
      navigation.push("Partners", {slug: email})
    })
  }
  return <BaseScreen>
    {error ? <FormError>{error}</FormError> : <View>
      <Text style={Styles.headingWithPadding}>{email}</Text>
      {lastName && <Form fields={[
        {
          label: 'First Name',
          name: 'first_name',
          value: firstName
        }, {
          label: 'Last Name',
          name: 'last_name',
          value: lastName
        }
      ]} submit="Save" onSubmit={save} />}
    </View>}
  </BaseScreen>
}

export default EditLurkerScreen
import React from "react"
import { Text, View } from "react-native"

import Colors from "../Colors"
import { isDesktop } from "../MediaQueries"
import { Styles, baseStyles } from "../Styles"
import BaseScreen from "../components/BaseScreen"
import Icon from "../components/Icon"
import { web } from "../providers/AuthProvider"

const SplitScreen = ({ left, right }) => {
  const desktop = isDesktop()
  const styles = baseStyles(desktop, web)
  const halfWidth = styles.width / 2
  const single = !left || right === undefined || !desktop
  if (left) {
    left = <>
      <Text style={{
        ...Styles.headingWithPadding,
        paddingTop: single ? 0 : 16
      }}>{left.title}</Text>
      {left.component}
    </>
  }
  if (single) {
    return <BaseScreen>
      {right ? right.component : left}
    </BaseScreen>
  }
  return <BaseScreen full>
    <View style={{
      flexDirection: "row",
      height: "100%"
    }}>
      <View style={{
        width: "50%"
      }}>
        <View style={{
          width: "100%",
          maxWidth: halfWidth,
          marginLeft: "auto"
        }}>
          {left}
        </View>
      </View>
      <View style={{
        width: "50%",
        borderLeft: "1px "+Colors.grey+" solid"
      }}>
        {right && <>
          <View style={{
            height: 70,
            padding: 10,
            borderBottom: "1px "+Colors.grey+" solid"
          }}>
            <View style={{
              width: "100%",
              maxWidth: halfWidth,
              marginRight: "auto",
              flexDirection: "row"
            }}>
              <Icon symbol="leftArrow_light" onPress={right.onBack} />
              <Text style={{
                fontWeight: "bold",
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 48
              }}>{right.title}</Text>
            </View>
          </View>
          <View style={{
            width: "100%",
            maxWidth: halfWidth,
            flex: 1
          }}>
            {right.component}
          </View>
        </>}
      </View>
    </View>
  </BaseScreen>
}

export default SplitScreen
import React, { useContext, useEffect, useState } from "react"
import { View, Text, TextInput, Pressable } from "react-native"

import BaseScreen from "../components/BaseScreen"
import SplitScreen from "../components/SplitScreen"
import Colors from "../Colors"
import { dateFormat } from "../Utils"
import { isDesktop } from "../MediaQueries"
import AvatarListItem from "../components/AvatarListItem"
import Icon from "../components/Icon"
import ScrollList from "../components/ScrollList"
import { AuthContext } from "../providers/AuthProvider"

const MessagesScreen = props => {
  const { navigation, route } = props
  const { api } = useContext(AuthContext)
  const [ partners, setPartners ] = useState([])
  const [ content, setContent ] = useState()
  const [ error, setError ] = useState()
  const [ readyToSend, setReadyToSend ] = useState()
  const [ readSlugs, setReadSlugs ] = useState([])

  const initialSlug = route.params !== undefined ? route.params.slug : undefined
  const [ slug, setSlug ] = useState(initialSlug)

  let summaryRoute = "messages"
  if (initialSlug) {
    summaryRoute += "?with="+initialSlug
  }

  const title = error ? slug+' could not be loaded' : (partners.find(partner => partner.slug === slug) || {}).name
  useEffect(() => {
    if (title) {
      navigation.setOptions({title})
    }
  }, [title])
  const desktop = isDesktop()
  const left = <ScrollList route={summaryRoute} autorefresh itemsSort={(a, b) => new Date(b.created_at) - new Date(a.created_at)} renderItem={message => {
    if (readSlugs.includes(message.slug)) {
      message.unread = false
    }
    return <AvatarListItem user={message} icon={message.unread && "unread_light"} onPress={() => {
      if (message.slug === slug) {
        return
      }
      if (desktop) {
        setReadyToSend()
        setError()
        setSlug(message.slug)
        window.history.replaceState(null, "", "/messages/"+message.slug)
      } else {
        navigation.push("Messages", {slug: message.slug})
      }
    }} />
  }} onItemsLoaded={(status, data) => {
    setPartners(data)
  }} />
  const right = <ScrollList route={"messages/"+slug} autorefresh stickToEnd itemsSort={(a, b) => new Date(a.created_at) - new Date(b.created_at)} style={{
    height: "100%"
  }} renderItem={message => <View style={{
    padding: 8,
    margin: 16,
    borderRadius: 14,
    backgroundColor: message.self ? Colors.light : Colors.veryLight,
    width: "50%",
    marginLeft: message.self ? "50%" : undefined
  }}>
    <Text>{message.content}</Text>
    <Text style={{ fontSize: 10 }}>{dateFormat(message.created_at)}</Text>
  </View>} footer={addCallback => readyToSend && <View style={{
    flexDirection: "row",
    borderTop: "1px "+Colors.grey+" solid"
  }}>
    <TextInput value={content} onChangeText={setContent} multiline style={{ flex: 1 }} />
    <Icon symbol="send_light" onPress={() => {
      if (content) {
        api.post("messages/"+slug, {content}).then(addCallback)
        setContent("")
      }
    }} />
  </View>} onItemsLoaded={(status, data) => {
    if (status == 200) {
      setReadSlugs([...readSlugs, slug])
      setReadyToSend(true)
    } else {
      setError(true)
    }
  }} />
  return <SplitScreen left={{
    component: left,
    title: "Messages"
  }} right={slug ? {
    component: right,
    title,
    onBack: () => {
      setSlug()
    }
  } : false} />
}

export default MessagesScreen
import React from "react"
import { Image, View, Text, TouchableOpacity } from "react-native"

const UpdateSection = props => {
  const { type, content } = props.data
  const style = {
    marginBottom: 10,
    flexDirection: 'row'
  }
  const children = type == 'text' ? <div dangerouslySetInnerHTML={{ __html: content }}></div> : (content || []).map((photo, i) => <Image key={i} source={photo} style={{
    width: 250,
    height: 250
  }} />)
  if (props.onPress) {
    return <TouchableOpacity onPress={props.onPress} style={style}>{children}</TouchableOpacity>
  } else {
    return <View style={style}>{children}</View>
  }
}

export default UpdateSection
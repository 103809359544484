import React from "react"
import { Button } from "react-native"
import { Editor, EditorState, RichUtils } from "draft-js"
import { convertFromHTML, convertToHTML } from "draft-convert"


class RichTextEdit extends React.Component {
  constructor(props) {
    super(props)
    this.setEditorState = this.setEditorState.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleKeyCommand = this.handleKeyCommand.bind(this)

    this.state = {
      editorState: props.content ? EditorState.createWithContent(convertFromHTML(props.content)) : EditorState.createEmpty()
    }
  }
  componentDidMount() {
    const { editorState } = this.state
    this.onChange(editorState)
  }
  setEditorState(editorState) {
    this.setState({editorState})
  }
  onChange(newEditorState) {
    const { onChange } = this.props
    const currentHTML = convertToHTML(newEditorState.getCurrentContent())
    onChange(currentHTML == '<p></p>' ? '' : currentHTML)

    this.setEditorState(newEditorState)
  }
  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.setEditorState(newState)
      return 'handled'
    }
    return 'not-handled'
  }
  applyStyle(style) {
    const { editorState } = this.state
    const editorStateFocused = EditorState.forceSelection(
      editorState,
      editorState.getSelection(),
    )
    this.setEditorState(RichUtils.toggleInlineStyle(editorStateFocused, style))
  }
  render() {
    const { editorState } = this.state
    return <>
      <Button title="B" onPress={() => this.applyStyle('BOLD')} />
      <Button title="I" onPress={() => this.applyStyle('ITALIC')} />
      <Editor
        editorState={editorState}
        onChange={this.onChange}
        handleKeyCommand={this.handleKeyCommand}
      />
    </>
  }
}

export default RichTextEdit
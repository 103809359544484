import React from "react"
import { Text } from "react-native"

import BaseScreen from "../../components/BaseScreen"

const PrivacyScreen = props => {
  return <BaseScreen>
    <Text>Privacy text</Text>
  </BaseScreen>
}

export default PrivacyScreen
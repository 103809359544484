import React, { useContext, useEffect, useState } from "react"
import { Image, Pressable, Text, View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import SplitScreen from "../components/SplitScreen"
import Colors from "../Colors"
import { isDesktop } from "../MediaQueries"
import Avatar from "../components/Avatar"
import AvatarListItem from "../components/AvatarListItem"
import FormButton from "../components/FormButton"
import Icon from "../components/Icon"
import PartnerProfile from "../components/PartnerProfile"
import ScrollList from "../components/ScrollList"
import { AuthContext } from "../providers/AuthProvider"

const PartnersScreen = props => {
  const { navigation, route } = props
  const { api } = useContext(AuthContext)
  const [ pending, setPending ] = useState([])
  const [ updatedConnections, setUpdatedConnections ] = useState({})

  const slug = route.params !== undefined ? decodeURIComponent(route.params.slug) : undefined
  const [ profileUser, setProfileUser ] = useState(slug && {slug})
  
  useEffect(() => {
    api.get("partners/pending").then(setPending)
  }, [])

  const desktop = isDesktop()
  useEffect(() => {
    navigation.setOptions({
      title: profileUser ? profileUser.name : "Partners"
    })
  }, [profileUser])

  const profileSlug = slug || (profileUser && profileUser.slug)
  const profile = profileSlug ? <PartnerProfile slug={profileSlug} updatedConnection={updatedConnections[profileSlug]} onConnectionChange={connection => {
    setUpdatedConnections({...updatedConnections, [profileSlug]: connection})
    if (connection.status == "Deleted") {
      if (slug) {
        navigation.goBack()
      } else {
        setProfileUser()
        return {unmount: true}
      }
    }
  }} onLoaded={data => {
    setProfileUser({
      ...profileUser,
      name: data.name
    })
  }} /> : null

  const showColumns = desktop && !profileUser
  const columns = [
    ["Platform", "40%", user => user.lurker ? "Email" : "App"],
    ["Status", "70%", user => (updatedConnections[user.slug] || user.connection).status]
  ]
  const left = <>
    {pending.map((pendingUser, i) => {
      const avatarUser = {...pendingUser}
      avatarUser.name += " would you like to partner with you"
      delete avatarUser.location
      const buttonStyle = {
        flex: 1,
        margin: 5
      }
      const respond = method => {
        return api[method]("take/"+pendingUser.slug).then(connection => {
          setPending(pending.filter(filterUser => filterUser.slug !== pendingUser.slug))
          setUpdatedConnections({...updatedConnections, [pendingUser.slug]: connection})
          if (connection.status == "Deleted" && pendingUser.slug == profileSlug) {
            setProfileUser()
          }
          return {unmount: true}
        })
      }
      return <View key={i} style={{
        backgroundColor: Colors.darkGrey,
        borderRadius: 7,
        padding: 10,
        marginVertical: 5
      }}>
        <Avatar user={avatarUser} size={48} />
        <View style={{ flexDirection: "row" }}>
          <FormButton title="Deny" onPress={() => {
            return respond("delete")
          }} style={{...buttonStyle, backgroundColor: "#fff"}} />
          <FormButton title="Partner" onPress={() => {
            return respond("post")
          }} style={buttonStyle} />
        </View>
      </View>
    })}
    {showColumns && <View style={{height: 16}}>
      <Text style={{
        position: "absolute",
        left: 48+17,
        fontWeight: "bold"
      }}>Name</Text>
      {columns.map((column, i) => <Text key={i} style={{
        position: "absolute",
        left: column[1],
        fontWeight: "bold"
      }}>{column[0]}</Text>)}
    </View>}
    <ScrollList route="partners" renderItem={user => {
      user.lurker = !user.slug

      const slug = user.lurker ? user.email : user.slug
      if (updatedConnections[slug] && updatedConnections[slug].status == "Deleted") {
        return null
      }
      return <AvatarListItem user={user} icon={(profileUser && profileUser.slug == slug) ? "box_filled" : "box"} onPress={() => {
        if (desktop) {
          setProfileUser({
            slug,
            name: user.name
          })
        } else {
          navigation.push("Partners", {slug})
        }
      }}>
        {showColumns && columns.map((column, i) => <Text key={i} style={{
          position: "absolute",
          left: column[1],
          top: 11.5,
          paddingHorizontal: 12,
          paddingVertical: 4,
          backgroundColor: Colors.dark
        }}>{column[2](user)}</Text>)}
      </AvatarListItem>
    }} />
  </>
  return <SplitScreen left={{
    component: left,
    title: "Partners"
  }} right={profileUser && {
    component: profile,
    title: profileUser.name,
    onBack: () => {
      setProfileUser()
    }
  }} />
}

export default PartnersScreen
import React, { useState } from "react"
import { ActivityIndicator, Pressable, Text } from "react-native"

import Colors from "../Colors"

const FormButton = props => {
  const { title, confirm, style } = props
  const [ text, setText ] = useState(title)
  const [ inProgress, setInProgress ] = useState(false)
  const [ confirmed, setConfirmed ] = useState(false)
  return inProgress ? <ActivityIndicator /> : <Pressable onPress={() => {
    if (confirm && !confirmed) {
      setInProgress(true)
      setText(confirm)
      setTimeout(() => {
        setInProgress(false)
        setConfirmed(true)
      }, 2000)
      return
    }
    if (!inProgress) {
      const promise = props.onPress()
      if (!promise) {
        return
      }
      setInProgress(true)
      promise.then(response => {
        if (!response.unmount) {
          setInProgress(false)
        }
      }).catch(() => {
        setInProgress(false)
      })
    }
  }} style={{
    backgroundColor: Colors.dark,
    borderRadius: 7,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginVertical: 8,
    paddingHorizontal: 8,
    ...style
  }}><Text>{text}</Text></Pressable>
}

export default FormButton
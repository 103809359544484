import React, { useContext, useEffect, useState } from "react"
import { Button, Text, View } from "react-native"

import { Header } from "../components/HeaderFooter"
import Icon from "../components/Icon"
import BaseScreen from "../components/BaseScreen"
import RichTextEdit from "../components/RichTextEdit"
import PhotoEdit from "../components/PhotoEdit"
import UpdateSection from "../components/UpdateSection"
import { AuthContext } from "../providers/AuthProvider"

const sectionStyles = {
  borderBottom: '2px #000 solid',
  marginBottom: 10
}

const EditUpdateScreen = () => {
  const { api, user } = useContext(AuthContext)
  const [sections, setSections] = useState([])
  const [html, setHTML] = useState()
  const [photos, setPhotos] = useState()
  
  const updateCurrentSection = currentSections => currentSections.map(section => {
    return section.editing ? {
      ...section,
      editing: false,
      content: section.type == 'text' ? html : photos
    } : section
  })
  const cleanSections = currentSections => {
    const filteredSections = []
    currentSections.filter(section => section.content).forEach(section => {
      if (filteredSections.length) {
        const lastSection = filteredSections[filteredSections.length-1]
        if (section.type == lastSection.type) {
          filteredSections[filteredSections.length-1] = {
            ...lastSection,
            content: section.type == 'text' ? lastSection.content + section.content : [...lastSection.content, ...section.content]
          }
          return
        }
      }
      filteredSections.push(section)
    })
    return filteredSections
  }
  
  const addSection = type => {
    setSections(currentSections => {
      const newSections = cleanSections(updateCurrentSection(currentSections))
      if (newSections.length && newSections[newSections.length-1].type == type) {
        return newSections.map((section, index) => {
          if (index === newSections.length-1) {
            return {
              ...section,
              editing: true
            }
          } else {
            return section
          }
        })
      } else {
        return [...newSections, {
          type,
          editing: true
        }]
      }
    })
  }
  const changeSection = i => {
    setSections(currentSections => {
      const updatedSections = updateCurrentSection(currentSections).map((currentSection, index) => {
        return index === i ? {
          ...currentSection,
          editing: true
        } : currentSection
      })
      return cleanSections(updatedSections)
    })
  }
  const removeSection = i => {
    setSections(currentSections => {
      const updatedSections = updateCurrentSection(currentSections).filter((section, index) => index !== i)
      return cleanSections(updatedSections)
    })
  }

  if (!user || user.role != 'gospelworker') {
    return <Text>You are a partner. You can't post updates</Text>
  }

  const lastSectionType = sections.length && sections[sections.length-1].type
  return <BaseScreen full noMobileTabs>
    <Header title="Share an Update" modal rightIcons={<Icon symbol="send_light" onPress={() => {
      alert('send')
    }} />} />
    {sections.length ? sections.map((section, i) => {
      if (section.editing) {
        return <View key={i}>
          <Button title="Remove Section" onPress={() => {
            removeSection(i)
          }} />
          {section.type == 'text' ? <RichTextEdit content={section.content} onChange={setHTML} /> : <PhotoEdit content={section.content} onChange={setPhotos} />}
        </View>
      } else {
        return <UpdateSection key={i} data={section} onPress={() => {
          changeSection(i)
        }} />
      }
    }) : <Text>Choose an option to get started</Text>}
    {lastSectionType != 'text' && <Button title="Write text" onPress={() => {
      addSection('text')
    }} />}
    {lastSectionType != 'photos' && <Button title="Add photos" onPress={() => {
      addSection('photos')
    }} />}
    {(sections.length && sections.find(section => section.editing)) ? <Button title="SAVE" onPress={() => {
      setSections(currentSections => cleanSections(updateCurrentSection(currentSections)))
    }} /> : null}
  </BaseScreen>
}

export default EditUpdateScreen
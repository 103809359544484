import React from "react"
import { createStackNavigator } from "@react-navigation/stack"

import HomeScreen from "../screens/HomeScreen"
import LoginScreen from "../screens/LoginScreen"
import ForgotScreen from "../screens/ForgotScreen"
import ResetScreen from "../screens/ResetScreen"
import RegisterScreen from "../screens/RegisterScreen"

const AuthStack = () => {
  const Stack = createStackNavigator()
  return <Stack.Navigator screenOptions={{
    headerShown:false,
    cardStyle: { backgroundColor: "#fff" }
  }}>
    <Stack.Screen name="Home" component={HomeScreen} />
    <Stack.Screen name="Login" component={LoginScreen} />
    <Stack.Screen name="Forgot" component={ForgotScreen} />
    <Stack.Screen name="Reset" component={ResetScreen} />
    <Stack.Screen name="Register" component={RegisterScreen} />
  </Stack.Navigator>
}

export default AuthStack
import React from "react"
import { Image, Pressable, View } from "react-native"

import Colors from "../Colors"

const sources = {
  box: require("../images/box.png"),
  box_filled: require("../images/box_filled.png"),
  cross_light: require("../images/cross_light.png"),
  edit_dark: require("../images/edit_dark.png"),
  ellipsis_light: require("../images/ellipsis_light.png"),
  leftArrow_light: require("../images/leftArrow_light.png"),
  plus_light: require("../images/plus_light.png"),
  profileImage: require("../images/profileImage.png"),
  rightArrow_dark: require("../images/rightArrow_dark.png"),
  search_light: require("../images/search_light.png"),
  search_dark: require("../images/search_dark.png"),
  send_light: require("../images/send_light.png"),
  skip_dark: require("../images/skip_dark.png"),
  unread_light: require("../images/unread_light.png")
}

const Icon = props => {
  const { style, narrow, onPress } = props
  let symbol = props.symbol

  const backgroundColor = symbol.includes("_light") ? Colors.light : Colors.dark

  const imageStyle = {}
  if (symbol == "rightArrow_light") {
    symbol = "leftArrow_light"
    imageStyle.transform = [{scaleX: -1}]
  } else if (!sources[symbol]) {
    symbol = symbol.replace("_light", "")
  }
  const [width, height] = {
    edit_dark: [58, 27],
    ellipsis_light: [44, 10],
    leftArrow_light: [48, 40],
    rightArrow_dark: [48, 40],
    skip_dark: [66, 33],
    unread_light: [16, 16]
  }[symbol] || [48, 48]
  if (width) {
    imageStyle.width = width / 2
  }
  if (height) {
    imageStyle.height = height / 2
  }
  const inner = <Image source={sources[symbol]} style={imageStyle} />
  const outerStyle = {
    backgroundColor,
    justifyContent: "center",
    alignItems: "center",
    ...style
  }
  if (!narrow) {
    outerStyle.width = 48
    outerStyle.height = 48
  }
  return onPress ? <Pressable style={outerStyle} onPress={onPress}>{inner}</Pressable> : <View style={outerStyle}>{inner}</View>
}

export default Icon
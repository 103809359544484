import React, { useCallback, useContext, useEffect, useState } from "react"
import { Pressable, Text, View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import Colors from "../Colors"
import { Styles } from "../Styles"
import FormButton from "../components/FormButton"
import FormError from "../components/FormError"
import { AuthContext } from "../providers/AuthProvider"

const PartnerEnterScreen = props => {
  const { navigation } = props
  const { api } = useContext(AuthContext)
  const [ digits, setDigits ] = useState([])
  const [ name, setName ] = useState()
  const [ error, setError ] = useState()

  const keydown = useCallback(event => {
    const key = event.key
    if (key == "Backspace") {
      setDigits([...digits.slice(0, digits.length-1)])
    } else if (digits.length != 6 && ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(key)) {
      const newDigits = [...digits, key]
      setDigits(newDigits)
      if (newDigits.length == 6) {
        const digitString = newDigits.join("")
        api.get("partner/code/"+digitString).then(response => {
          setName(response.name)
          setError()
        }).catch(response => {
          if (response.status == 404) {
            setError(digitString+" was not found")
          } else {
            setError(response.message || "Something went wrong with "+digitString)
          }
          setDigits([])
        })
      }
    }
  }, [digits])

  useEffect(() => {
    document.addEventListener("keydown", keydown, false)
    return () => { document.removeEventListener("keydown", keydown, false) }
  }, [digits])

  return <BaseScreen noMobileTabs>
    {name ? <>
      <Text style={{
        ...Styles.heading,
        textAlign: "center",
        marginBottom: 50
      }}>Partner with {name}?</Text>
      <FormButton title="Partner" onPress={() => {
        return api.put("partner/code/"+digits.join(""), {accept: true}).then(response => {
          navigation.push("Profile", {slug: response.slug})
          return {unmount: true}
        })
      }} />
      <FormButton title="Deny" onPress={() => {
        return api.put("partner/code/"+digits.join("")).then(response => {
          setDigits([])
          setName()
          return {unmount: true}
        })
      }} style={{backgroundColor: Colors.light}} />
    </> : <>
      <Text style={{
        ...Styles.heading,
        textAlign: "center",
        marginBottom: 25
      }}>Enter a Gospel Workers 6-digit Code</Text>
      {error && <FormError style={{
        textAlign: "center",
        marginBottom: 25
      }}>{error}</FormError>}
      <View style={{
        flexDirection: "row",
        justifyContent: "center"
      }}>
        {[...Array(6).keys()].map(i => <Pressable key={i} style={{
          backgroundColor: Colors.lightGrey,
          width: 48,
          height: 64,
          margin: 4,
          borderRadius: 7,
          justifyContent: "center",
          alignItems: "center",
          outline: "none"
        }} onPress={() => {
          setDigits(digits.slice(0, i))
        }}>
          <Text style={{ fontSize: 24 }}>{digits[i]}</Text>
        </Pressable>)}
      </View>
    </>}
  </BaseScreen>
}

export default PartnerEnterScreen
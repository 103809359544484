import React, { useContext, useEffect, useState } from "react"
import { Pressable, Text, View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import Avatar from "../components/Avatar"
import Icon from "../components/Icon"
import Form from "../components/Form"
import FormButton from "../components/FormButton"
import { AuthContext } from "../providers/AuthProvider"

const SettingsScreen = props => {
  const { navigation } = props
  const { api, user } = useContext(AuthContext)
  const [ data, setData ] = useState()

  useEffect(() => {
    api.get("settings").then(setData)
  }, [])

  if (!user) {
    return null
  }

  const save = data => {
    return api.put("settings", data).then(() => {
      return {message: "Your settings have been updated"}
    })
  }
  return <BaseScreen noMobileTabs>
    <View style={{
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    }}>
      <Avatar link user={user} size={48} />
      <Icon symbol="edit_dark" onPress={() => {
        navigation.push("EditProfile")
      }} />
    </View>
    {data && <Form fields={[
      {
        label: "Email Notifications",
        name: "email_subscription",
        type: "boolean",
        value: data.email_subscription
      }, {
        label: "Batch Notifications",
        name: "batch_notifications",
        type: "boolean",
        value: data.batch_notifications
      }
    ]} onAutoSave={save} />}
    
    {["Help", "Privacy", "About"].map((link, i) => <Pressable key={i} onPress={() => {
      navigation.push(link)
    }} style={{
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    }}>
      <Text style={{
        fontWeight: "bold"
      }}>{link}</Text>
      <Icon symbol="rightArrow_light" />
    </Pressable>)}
    
    {(data && Object.keys(data.tokens).length) ? <>
      <Text style={{
        fontWeight: "bold"
      }}>Devices</Text>
      {Object.keys(data.tokens).map(id => {
        const name = data.tokens[id]
        return <View key={id} style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <Text>{name}</Text>
          <FormButton title={"Log out "+name} confirm="Are you sure?" onPress={() => {
            return api.delete("token/"+id).then(() => {
              const tokens = {...data.tokens}
              delete tokens[id]
              setData({
                ...data,
                tokens
              })
            })
          }} />
        </View>})}
    </> : null}

    <FormButton title="Delete my account" confirm="Are you sure you would like to delete your account?" onPress={() => {
      return api.delete("user").then(api.logout).then(() => {
        return {unmount: true}
      })
    }} style={{ marginTop: 15 }} />
    <FormButton title="Log out" onPress={api.logout} style={{ marginTop: 30 }} />
  </BaseScreen>
}

export default SettingsScreen
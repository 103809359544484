import React from "react"
import { Text } from "react-native"

import BaseScreen from "../../components/BaseScreen"

const AboutScreen = () => {
  return <BaseScreen>
    <Text>About text</Text>
  </BaseScreen>
}

export default AboutScreen
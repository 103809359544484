export const Styles = {
  heading: {
    fontSize: 24,
    fontWeight: "bold"
  },
  headingWithPadding: {
    fontSize: 24,
    fontWeight: "bold",
    paddingBottom: 10
  }
}

export const baseStyles = (desktop, web) => {
  return {
    top: (desktop && web) ? 16 : 27,
    width: 875
  }
}
import React from "react"
import { Text } from "react-native"

import { Styles } from "../Styles"
import BaseScreen from "../components/BaseScreen"
import ScrollList from "../components/ScrollList"
import Update from "../components/Update"

const UpdatesScreen = () => {
  return <BaseScreen>
    <Text style={Styles.headingWithPadding}>Updates</Text>
    <ScrollList route="updates" autorefresh="top" manualrefresh itemsSort={(a, b) => new Date(b.created_at) - new Date(a.created_at)} renderItem={update => <Update content={update} />} />
  </BaseScreen>
}

export default UpdatesScreen
import React, { useContext } from "react"
import { Pressable, Text, View } from "react-native"
import { useNavigation } from "@react-navigation/native"

import ProfileImage from "../components/ProfileImage"
import { AuthContext } from "../providers/AuthProvider"

const Avatar = props => {
  const { user } = useContext(AuthContext)
  let avatarUser = props.user
  if (!user || !avatarUser) {
    return null
  }

  const inner = <>
    <ProfileImage user={avatarUser} size={props.size} />
    <View style={{
      marginLeft: 17,
      justifyContent: "space-around"
    }}>
      <Text style={{
        fontWeight: "bold"
      }}>{avatarUser.name}</Text>
      {avatarUser.location ? <Text>{avatarUser.location}</Text> : null}
    </View>
  </>

  const self = avatarUser && avatarUser.slug == user.slug
  const navigation = useNavigation()
  const routes = navigation.getState().routes
  const currentRoute = routes[routes.length - 1]
  let link = false
  if (props.link) {
    if (self) {
      link = currentRoute.name != "Settings"
    } else {
      link = currentRoute.name != "Profile" || currentRoute.params.slug != avatarUser.slug
    }
  }
  const outerStyles = {
    flexDirection: "row"
  }
  return link ? <Pressable onPress={() => {
    if (self) {
      navigation.push("Settings")
    } else {
      navigation.push("Profile", {slug: avatarUser.slug})
    }
  }} style={outerStyles}>{inner}</Pressable> : <View style={outerStyles}>{inner}</View>
}

export default Avatar
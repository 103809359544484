import React, { Component, useContext } from "react"
import { Button, Image, Text, View } from "react-native"
import { createStackNavigator } from "@react-navigation/stack"
import { getFocusedRouteNameFromRoute } from "@react-navigation/native"

import { Header } from "../components/HeaderFooter"
import Icon from "../components/Icon"
import UpdatesScreen from "../screens/UpdatesScreen"
import GospelWorkerProfileScreen from "../screens/GospelWorkerProfileScreen"
import EditProfileScreen from "../screens/EditProfileScreen"
import UpdateScreen from "../screens/UpdateScreen"
import EditUpdateScreen from "../screens/EditUpdateScreen"
import EditLurkerScreen from "../screens/EditLurkerScreen"
import GospelWorkersScreen from "../screens/GospelWorkersScreen"
import PartnerAskScreen from "../screens/PartnerAskScreen"
import PartnerEnterScreen from "../screens/PartnerEnterScreen"
import PartnersScreen from "../screens/PartnersScreen"
import ShareScreen from "../screens/ShareScreen"
import MessagesScreen from "../screens/MessagesScreen"
import SettingsScreen from "../screens/SettingsScreen"
import HelpScreen from "../screens/info/HelpScreen"
import PrivacyScreen from "../screens/info/PrivacyScreen"
import AboutScreen from "../screens/info/AboutScreen"
import { AuthContext } from "../providers/AuthProvider"

const getTabs = () => {
  const { user } = useContext(AuthContext)
  const options = {
    tabBarIcon: props => {
      return <Icon symbol={props.focused ? "box_filled" : "box"} />
    }
  }
  const tabs = [
    {name: "Updates", component: UpdatesScreen, options},
    {name: "GospelWorkers", component: GospelWorkersScreen, options: { title: "Gospel Workers", ...options }},
    {name: "Partners", component: PartnersScreen, options: { tabBarLabel: "Partners", ...options }}
  ]
  if (!user || user.role == "gospelworker") {
    tabs.push({name: "Share", component: ShareScreen, options})
  }
  tabs.push({name: "Messages", component: MessagesScreen, options: { tabBarLabel: "Messages", ...options }})
  return tabs
}

const AppStack = () => {
  const Stack = createStackNavigator()
  return <Stack.Navigator screenOptions={{
    header: ({ options }) => {
      return <Header title={options.title} modal={options.modal} />
    },
    cardStyle: { backgroundColor: "#fff" },
    animationEnabled: false
  }}>
    {getTabs().map((tab, i) => <Stack.Screen key={i} name={tab.name} component={tab.component} options={tab.options} />)}
    <Stack.Screen name="Update" component={UpdateScreen} />
    <Stack.Screen name="EditUpdate" component={EditUpdateScreen} options={{ title: "Share an Update", headerShown: false }} />
    <Stack.Screen name="EditLurker" component={EditLurkerScreen} options={{ title: "People" }} />
    <Stack.Screen name="PartnerAsk" component={PartnerAskScreen} options={{ title: "Add a new Partner", modal: true }} />
    <Stack.Screen name="PartnerEnter" component={PartnerEnterScreen} options={{ title: "Partner with a Gospel Worker", modal: true }} />
    <Stack.Screen name="Profile" component={GospelWorkerProfileScreen} />
    <Stack.Screen name="EditProfile" component={EditProfileScreen} options={{ title: "Edit Profile" }} />
    <Stack.Screen name="Settings" component={SettingsScreen} />
    <Stack.Screen name="Help" component={HelpScreen} />
    <Stack.Screen name="Privacy" component={PrivacyScreen} />
    <Stack.Screen name="About" component={AboutScreen} />
  </Stack.Navigator>
}

export default AppStack
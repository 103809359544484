import React, { useContext, useEffect, useState } from "react"

import Update from "../components/Update"
import BaseScreen from "../components/BaseScreen"
import { AuthContext } from "../providers/AuthProvider"

const UpdateScreen = props => {
  const { api } = useContext(AuthContext)
  const { route } = props
  const [update, setUpdate] = useState()
  const slug = route.params.slug
  const id = route.params.id
  useEffect(() => {
    api.get("update/"+slug+"/"+id).then(setUpdate)
  }, [])
  return <BaseScreen>
    {update ? <Update content={update} /> : null}
  </BaseScreen>
}

export default UpdateScreen
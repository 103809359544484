import { useState } from "react"

export const dateFormat = string => {
  const date = new Date(string)
  const now = new Date()

  const hours = date.getHours()
  const a = hours > 11 ? 'pm' : 'am'
  let result = hours % 12
  if (result == 0) {
    result = 12
  }
  let minutes = date.getMinutes().toString()
  if (minutes.length == 1) {
    minutes = '0'+minutes
  }
  result = result.toString()+':'+minutes+' '+a

  const year = date.getFullYear()
  const sameYear = year == now.getFullYear()
  const sameMonth = date.getMonth() == now.getMonth() && sameYear
  if (sameMonth) {
    if (date.getDate() == now.getDate()) {
      // Today
      if (hours == now.getHours()) {
        // Minutes ago
        const hourAgo = new Date()
        hourAgo.setHours(hourAgo.getHours() - 1)
        if (date > hourAgo) {
          const minutesAgo = now.getMinutes() - date.getMinutes()
          if (minutesAgo == 0) {
            return 'Now'
          } else if (minutesAgo == 1) {
            return '1 minute ago'
          } else {
            return minutesAgo.toString()+' minutes ago'
          }
        }
      }
      return result
    } else {
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      if (date.getDate() == yesterday.getDate()) {
        // Yesterday
        return result+', yesterday'
      }
    }
  }

  let monthDay = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][date.getMonth()]+' '+date.getDate()
  if (!sameYear) {
    monthDay += ' '+year
  }
  return monthDay+', '+result
}

export const createThrottle = () => {
  const [ timeoutVar, setTimeoutVar ] = useState()
  return (callback, delay, wait) => {
    if (wait == 'waitBefore') {
      if (timeoutVar) {
        clearTimeout(timeoutVar)
      }
      setTimeoutVar(setTimeout(() => {
        setTimeoutVar(null)
        callback()
      }, delay))
    } else {
      if (!timeoutVar) {
        callback()
        setTimeoutVar(setTimeout(() => {
          setTimeoutVar(null)
        }, delay))
      }
    }
  }
}
import React, { useContext } from "react"

import BaseScreen from "../components/BaseScreen"
import Form from "../components/Form"
import { AuthContext } from "../providers/AuthProvider"

const EditProfileScreen = props => {
  const { navigation } = props
  const { api, user, refreshUser } = useContext(AuthContext)

  if (!user) {
    return null
  }

  const save = data => {
    return api.put("user/profile-information", data).then(() => {
      refreshUser()
      navigation.push("Settings")
    })
  }
  const fields = [
    {
      label: "First Name",
      name: "first_name",
      value: user.first_name
    }, {
      label: "Last Name",
      name: "last_name",
      value: user.last_name
    }, {
      label: "E-mail",
      name: "email",
      value: user.email
    }, {
      label: "Location",
      name: "location",
      value: user.location
    }
  ]
  if (user.role != "admin") {
    fields.push({
      label: "Role",
      name: "role",
      type: "radio",
      value: user.role,
      options: [
        {
          title: "Gospel Worker",
          value: "gospelworker"
        }, {
          title: "Partner",
          value: "partner"
        }
      ]
    })
  }
  fields.push({
    label: "Profile Image",
    name: "profile_image",
    type: "image",
    value: user.profile_image
  })
  return <BaseScreen>
    <Form fields={fields} submit="Save" onSubmit={save} />
  </BaseScreen>
}

export default EditProfileScreen
import React, { useContext, useEffect, useState } from "react"
import { View } from "react-native"
import { useNavigation } from "@react-navigation/native"

import Colors from "../Colors"
import { AuthContext } from "../providers/AuthProvider"
import Icon from "../components/Icon"
import FormButton from "../components/FormButton"

const ProfileActions = props => {
  const { profileUser, action, onConnectionChange } = props
  const { api } = useContext(AuthContext)
  const [ actions, setActions ] = useState({})
  const [ open, setOpen ] = useState(false)

  useEffect(() => {
    setActions(profileUser.connection.actions)
  }, [profileUser])

  const handleResponse = (connection, type) => {
    const result = onConnectionChange && onConnectionChange(connection)
    if (result && result.unmount) {
      return {unmount: true}
    } else {
      setActions(connection.actions)
      if (connection.actions[type] === undefined) {
        return {unmount: true}
      }
    }
  }

  const navigation = useNavigation()
  const buttons = []
  const firstButtonStyle = {
    flex: 1,
    marginVertical: 0
  }
  if (profileUser.lurker) {
    buttons.push(<FormButton title="Edit" onPress={() => {
      navigation.push("EditLurker", {email: profileUser.slug})
    }} style={firstButtonStyle} />)
  } else {
    buttons.push(<FormButton title="Message" onPress={() => {
      navigation.push("Messages", {slug: profileUser.slug})
    }} style={firstButtonStyle} />)
  }
  if (actions.post) {
    buttons.push(<FormButton key="post" title={actions.post} onPress={() => {
      return api.post(action+"/"+profileUser.slug).then(connection => {
        return handleResponse(connection, "post")
      })
    }} style={{ marginVertical: 0 }} />)
  }
  if (actions.delete) {
    buttons.push(<FormButton key="delete" title={actions.delete} onPress={() => {
      return api.delete(action+"/"+profileUser.slug).then(connection => {
        return handleResponse(connection, "delete")
      })
    }} style={{
      marginVertical: 0,
      backgroundColor: Colors.lightGrey
    }} />)
  }
  return buttons.length && <View style={{ marginVertical: 8 }}>
    <View style={{
      flexDirection: "row",
      alignItems: "center"
    }}>
      {buttons[0]}
      {buttons.length > 1 && <Icon symbol="ellipsis_light" style={{ marginLeft: 8 }} onPress={() => {
        setOpen(!open)
      }} />}
    </View>
    {open && buttons.slice(1)}
  </View>
}

export default ProfileActions
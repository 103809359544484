import React, { useContext } from "react"
import { View } from "react-native"

import Form from "../components/Form"
import FormButton from "../components/FormButton"
import { AuthContext } from "../providers/AuthProvider"

const LoginScreen = props => {
  const { navigation } = props
  const { api } = useContext(AuthContext)

  const login = data => {
    return api.login(data).then(() => {
      return {unmount: true}
    })
  }
  return <View style={{
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  }}>
    <View style={{
      width: "100%",
      maxWidth: 340
    }}>
      <Form fields={[
        {
          label: 'Email',
          name: 'email',
          type: 'email'
        }, {
          label: 'Password',
          name: 'password',
          type: 'password'
        }
      ]} onSubmit={login} />
      <FormButton title="Forgot Password" onPress={() => {
        navigation.push('Forgot')
      }} />
    </View>
  </View>
}

export default LoginScreen
import React, { useContext, useEffect, useState } from "react"
import { Text, TextInput, View } from "react-native"

import Colors from "../Colors"
import { Styles } from "../Styles"
import { createThrottle } from "../Utils"
import { AuthContext } from "../providers/AuthProvider"
import ProfileImage from "../components/ProfileImage"
import ProfileActions from "../components/ProfileActions"


const PartnerProfile = props => {
  const { slug, onLoaded, updatedConnection, onConnectionChange } = props
  const { api, user } = useContext(AuthContext)
  const [ profileUser, setProfileUser ] = useState()
  const [ notes, setNotes ] = useState("")
  const throttle = createThrottle()

  useEffect(() => {
    let valid = true
    api.get("partner/"+slug).then(otherUser => {
      if (!valid) {
        return
      }
      otherUser.slug = slug

      onLoaded(otherUser)

      // In case there has been an update since the partners list was fetched
      onConnectionChange(otherUser.connection)
      if (otherUser.connection.notes === null) {
        otherUser.connection.notes = ""
      }
      setNotes(otherUser.connection.notes)
      setProfileUser(otherUser)
    })
    return () => { valid = false }
  }, [slug])

  useEffect(() => {
    if (updatedConnection) {
      setProfileUser(existingUser => {
        return {
          ...existingUser,
          connection: updatedConnection
        }
      })
    }
  }, [updatedConnection])

  const connection = updatedConnection || (profileUser && profileUser.connection)
  useEffect(() => {
    throttle(() => {
      if (profileUser && notes !== connection.notes) {
        setProfileUser({
          ...profileUser,
          connection: {
            ...connection,
            notes
          }
        })
        api.put("partner/"+slug, {notes})
      }
    }, 5000, 'waitBefore')
  }, [notes])

  let fields = []
  if (profileUser) {
    if (profileUser.lurker) {
      fields.push(["Email", profileUser.slug])
    }
    fields = fields.concat([
      ["Status", connection.status, true],
      ["Platform", profileUser.lurker ? "Email" : "App", true]
    ])
  }
  return profileUser ? <>
    <View style={{ textAlign: "center", alignItems: "center" }}>
      <ProfileImage user={profileUser} size={250} />
      <Text style={Styles.headingWithPadding}>{profileUser.name}</Text>
    </View>
    
    <ProfileActions profileUser={profileUser} action="take" onConnectionChange={onConnectionChange} />
  
    <View>
      {fields.map((row, i) => <View key={i} style={{
        flexDirection: "row",
        marginHorizontal: 16,
        marginVertical: 8
      }}>
        <Text style={{
          color: Colors.darkGrey,
          width: 100
        }}>{row[0]}</Text>
        <Text style={row[2] && {
          backgroundColor: Colors.dark,
          paddingHorizontal: 12,
          paddingVertical: 4,
          marginTop: -4,
          borderRadius: 7
        }}>{row[1]}</Text>
      </View>)}
    </View>
    <View style={{
      backgroundColor: Colors.lightGrey,
      padding: 16
    }}>
      <Text style={{ color: Colors.darkGrey }}>Notes</Text>
      <TextInput value={notes} onChangeText={setNotes} multiline style={{ height: 50 }} />
    </View>
 </> : <Text>No profile</Text>
}

export default PartnerProfile
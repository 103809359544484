import React, { useContext, useEffect, useRef, useState } from "react"
import { FlatList, Text, View } from "react-native"
import { RefreshControl } from "react-native-web-refresh-control"

import { AuthContext } from "../providers/AuthProvider"

const ScrollList = props => {
  const { api } = useContext(AuthContext)
  const { route, renderItem, autorefresh, manualrefresh, itemsSort, onItemsLoaded, stickToEnd, style, header, footer } = props
  const [ items, setItems ] = useState([])
  const [ refreshing, setRefreshing ] = useState(false)
  const endReachedRef = useRef(false)
  const scrollRef = useRef(0)
  const flatListRef = useRef()
  const pendingScrollToEndRef = useRef(false)
  
  const key = item => item.id !== undefined ? item.id : item.slug
  const addItems = newItems => {
    const newItemKeys = newItems.map(key)
    setItems(currentItems => {
      const totalItems = currentItems.filter(item => !newItemKeys.includes(key(item))).concat(newItems)
      if (itemsSort) {
        totalItems.sort(itemsSort)
      }
      if (onItemsLoaded) {
        setTimeout(() => {
          onItemsLoaded(200, totalItems)
        }, 0)
      }
      return totalItems
    })
  }

  const apiGet = (url, currentItems) => {
    return api.get(url).then(response => {
      if (response.finished) {
        endReachedRef.current = true
      }

      addItems(response.results)
    }).catch(error => {
      if (onItemsLoaded) {
        onItemsLoaded(error.status, [])
      }
    })
  }
  const fetch = currentItems => {
    if (endReachedRef.current) {
      return
    }
    
    let url = route
    if (currentItems.length) {
      if (stickToEnd) {
        url += "?before="+key(currentItems[0])
      } else {
        url += "?after="+key(currentItems[currentItems.length-1])
      }
    }
    return apiGet(url, currentItems)
  }
  useEffect(() => {
    endReachedRef.current = false
    setItems([])
    fetch([]).then(() => {
      pendingScrollToEndRef.current = true
    })
    
    if (autorefresh) {
      const interval = setInterval(() => {
        if (autorefresh === "top" && scrollRef.current > 100) {
          return
        }
        apiGet(route)
      }, 30*1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [route])

  const onRefresh = () => {
    setRefreshing(true)
    apiGet(route).finally(() => {
      setRefreshing(false)
    })
  }

  const innerStyle = footer ? {flex: 1} : style
  const list = <FlatList ref={ref => {
    flatListRef.current = ref
  }} data={items.length ? items : [{slug: 'empty'}]} renderItem={row => {
    let component
    if (row.item.slug === 'empty') {
      component = <Text>empty</Text>
    } else {
      component = renderItem(row.item, addItems)
    }
    if (!row.index && header) {
      component = <>
        {header}
        {component}
      </>
    }
    return component
  }} keyExtractor={key} onScroll={event => {
    const y = event.nativeEvent.contentOffset.y
    if (autorefresh === "top") {
      scrollRef.current = y
    }
    if (stickToEnd && items.length && scrollRef.current <= 100) {
      fetch(items)
    }
  }} onEndReached={() => {
    if (!stickToEnd && items.length) {
      fetch(items)
    }
  }} onContentSizeChange={() => {
    if (pendingScrollToEndRef.current) {
      pendingScrollToEndRef.current = false
      setTimeout(() => {
        flatListRef.current.scrollToEnd({ animated: false })
      }, 100)
    }
  }} style={innerStyle} onEndReachedThreshold={0.1} refreshControl={manualrefresh && <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />} />
  const addItemsAndScroll = newItems => {
    addItems(newItems)
    pendingScrollToEndRef.current = true
  }
  return footer ? <View style={style}>
    {list}
    {footer(addItemsAndScroll)}
  </View> : list
}

export default ScrollList
import React from "react"
import { Text } from "react-native"

import { Styles } from "../Styles"
import BaseScreen from "../components/BaseScreen"
import AvatarListItem from "../components/AvatarListItem"
import ScrollList from "../components/ScrollList"

const GospelWorkersScreen = props => {
  const { navigation } = props

  return <BaseScreen>
    <Text style={Styles.headingWithPadding}>Gospel Workers</Text>
    <ScrollList route={"gospelworkers"} renderItem={user => <AvatarListItem user={user} onPress={() => {
      navigation.push("Profile", {slug: user.slug})
    }} />} />
  </BaseScreen>
}

export default GospelWorkersScreen
import React, { useRef, useState } from "react"
import { Animated, Button, Dimensions, Image, Text, View } from "react-native"
import { useMediaQuery } from "react-responsive"

import Colors from "../Colors"
import { Styles } from "../Styles"
import { web } from "../providers/AuthProvider"
import Icon from "../components/Icon"
import FormButton from "../components/FormButton"


const HomeScreen = props => {
  const { navigation } = props
  const slides = [
    'Partner\nrelationally',
    'Chat\nindividually',
    'Share\ncorporately'
  ]
  const [ index, setIndex ] = useState(0)
  const [ width, setWidth ] = useState(0)
  const [ skipped, setSkipped ] = useState(false)
  
  const advanceAV = useRef(new Animated.Value(0)).current
  const skipAV = useRef(new Animated.Value(0)).current
  
  const updateWidth = () => {
    setWidth(Dimensions.get("window").width)
  }
  const skip = () => {
    updateWidth()
    Animated.timing(skipAV, {
      toValue: 1,
      duration: 500,
      useNativeDriver: !web
    }).start(() => {
      skipAV.setValue(0)
      setSkipped(true)
    })
  }
  const advance = () => {
    if (index == slides.length - 1) {
      skip()
      return
    }
    updateWidth()
    Animated.timing(advanceAV, {
      toValue: 1,
      duration: 500,
      useNativeDriver: !web
    }).start(() => {
      advanceAV.setValue(0)
      setIndex(index => index + 1)
    })
  }
  
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const textStyles = {
    ...Styles.headingWithPadding,
    textAlign: "center"
  }
  return <Animated.View style={{
    position: "absolute",
    left: 0,
    top: isPortrait ? 0 : "25%",
    width: "100%",
    height: isPortrait ? "100%" : "50%",
    transform: [{
      translateX: skipAV.interpolate({
        inputRange: [0, 1],
        outputRange: [0, width * -1]
      })
    }]
  }}>
    {!skipped ? <View onClick={advance} style={{
      height: "100%",
      overflow: "hidden"
    }}>
      {slides.map((slide, i) => <Animated.Text key={i} style={{
        ...textStyles,
        position: "absolute",
        top: isPortrait ? "10%" : 0,
        left: (Math.max(i-index, -1) * 100).toString()+"%",
        width: "100%",
        height: "100%",
        transform: [{
          translateX: advanceAV.interpolate({
            inputRange: [0, 1],
            outputRange: [0, width * -1]
          }),
        }]
      }}>{slide}</Animated.Text>)}
      <View style={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        alignItems: "center"
      }}>
        <View style={{
          width: "100%",
          maxWidth: 800,
          height: 100
        }}>
          {slides.map((_, i) => <View key={i} style={{
            backgroundColor: index == i ? "#000" : Colors.darkGrey,
            borderRadius: 4,
            width: 8,
            height: 8,
            position: "absolute",
            left: "50%",
            bottom: 20+(48/2),
            marginLeft: (i - 1) * 16
          }} />)}
          <Icon symbol="skip_dark" onPress={skip} style={{
            position: "absolute",
            left: 20,
            bottom: 20
          }} />
          <Icon symbol="rightArrow_dark" onPress={advance} style={{
            position: "absolute",
            right: 20,
            bottom: 20
          }} />
        </View>
      </View>
    </View> : null}
    <View style={{
      position: "absolute",
      left: skipped ? 0 : "100%",
      width: "100%",
      height: "100%"
    }}>
      <Text style={{
        ...textStyles,
        marginTop: isPortrait ? "10%" : 0,
        marginBottom: 28,
      }}>Let's partner</Text>
      <View style={isPortrait ? {
        position: "absolute",
        left: 20,
        bottom: 12,
        width: "calc(100% - 40px)"
      } : {
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: 340
      }}>
        <FormButton title="Create Account" onPress={() => {
          navigation.push("Register")
        }} />
        <FormButton title="Log In" onPress={() => {
          navigation.push("Login")
        }} style={{backgroundColor: Colors.light}} />
      </View>
    </View>
  </Animated.View>
}

export default HomeScreen
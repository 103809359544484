import React, { useContext } from "react"
import { Image, View, Text, Pressable } from "react-native"
import { useNavigation } from "@react-navigation/native"

import FormButton from "../components/FormButton"
import { AuthContext } from "../providers/AuthProvider"
import Avatar from "./Avatar"
import Colors from "../Colors"
import { dateFormat } from "../Utils"
import UpdateSection from "./UpdateSection"

const Update = props => {
  const { api, user } = useContext(AuthContext)
  const { updateItems, content } = props
  const navigation = useNavigation()

  const created_at = dateFormat(content.published_at || content.created_at)

  let avatarUser
  if (props.user && props.user.name) {
    avatarUser = {...props.user}
  } else {
    avatarUser = {};
    ["name", "slug", "location", "profile_image"].forEach(k => {
      avatarUser[k] = content["user_"+k]
    })
  }
  let value = avatarUser.location
  if (value) {
    value += ", "+created_at
  } else {
    value = created_at
  }
  avatarUser.location = value

  return <View style={{
    backgroundColor: Colors.darkGrey,
    borderRadius: 7,
    marginVertical: 5
  }}>
    <View style={{padding: 10}}>
      <Avatar link user={avatarUser} size={50} />
      <Text>{content.title}</Text>
      {content.sections.map((section, i) => <UpdateSection key={i} data={section} />)}
    </View>
    {updateItems && !content.published_at && <View style={{
      borderTop: "1px #fff solid",
      padding: 10,
      flexDirection: "row",
      justifyContent: "flex-end"
    }}>
      <FormButton title="Edit" style={{ width: 150, backgroundColor: "white", marginRight: 10}} onPress={() => {
        navigation.push("EditUpdate", {id: content.id})
      }} />
      <FormButton title="Share" confirm="Are you sure?" style={{ width: 150}} onPress={() => {
        return api.put("update/"+content.id, {published: true}).then(updatedItem => {
          updatedItem.id = content.id
          updateItems([updatedItem])
          return {unmount: true}
        })
      }} />
    </View>}
  </View>
}

export default Update
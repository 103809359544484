import React, { useContext, useEffect, useState } from "react"
import { Text, View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import { Styles } from "../Styles"
import Form from "../components/Form"
import FormButton from "../components/FormButton"
import { AuthContext } from "../providers/AuthProvider"

const PartnerAskScreen = props => {
  const { navigation } = props
  const { api } = useContext(AuthContext)
  const [ code, setCode ] = useState()
  const [ emailMode, setEmailMode ] = useState()
  const [ error, setError ] = useState()

  useEffect(() => {
    api.post("partner/code").then(response => {
      setCode(response.digits)
    })
  }, [])

  useEffect(() => {
    if (code && !emailMode) {
      const interval = setInterval(function () {
        api.get("partner/code/"+code+"/ping").then(response => {
          if (response.slug) {
            navigation.navigate("Partners", {slug: response.slug})
            clearInterval(interval)
          }
        }).catch(response => {
          if (response.status == 404) {
            setError("Code expired")
          } else {
            setError(response.message || "Error occurred")
          }
          clearInterval(interval)
        })
      }, 1500)
      return () => {
        clearInterval(interval)
      }
    }
  }, [emailMode, code])

  const addLurker = data => {
    return api.post("lurker", data).then(() => {
      navigation.navigate("Partners", {slug: data.email})
    })
  }
  return <BaseScreen noMobileTabs>
    {emailMode ? <>
      <Text style={{
        ...Styles.headingWithPadding,
        textAlign: "center"
      }}>E-mail Only Partner</Text>
      <Form fields={[
        {
          label: "First Name",
          name: "first_name"
        }, {
          label: "Last Name",
          name: "last_name"
        }, {
          label: "Email",
          name: "email",
          type: "email"
        }
      ]} submit="Add Partner" onSubmit={addLurker} />
    </> : <>
      <Text style={{
        ...Styles.heading,
        textAlign: "center",
        marginBottom: 50
      }}>Ask a Partner to enter this code</Text>
      {error ? <Text style={{
        fontSize: 36,
        fontWeight: "bold",
        textAlign: "center",
        color: "#f00"
      }}>{error}</Text> : <Text style={{
        fontSize: 36,
        fontWeight: "bold",
        textAlign: "center",
        letterSpacing: 6
      }}>{code || " "}</Text>}
      <FormButton title="Add a Partner with email" onPress={() => {
        setEmailMode(true)
      }} style={{ marginTop: 100 }} />
    </>}
  </BaseScreen>
}

export default PartnerAskScreen
import React, { useState } from "react"
import { View, Switch, Text, TextInput } from "react-native"
import Dropzone from "react-dropzone"

import Colors from "../Colors"
import FormButton from "./FormButton"
import FormError from "./FormError"
import PhotoEdit from "./PhotoEdit"

const Form = props => {
  const initialData = {}
  props.fields.forEach(field => {
    let value = field.value
    if (["email", "password", "textarea"].includes(field.type) && !value) {
      value = ""
    }
    initialData[field.name] = value
  })
  const [ data, setData ] = useState(initialData)
  const [ response, setResponse ] = useState({})
  
  const setValue = (name, value) => {
    const newState = {
      [name]: value
    }
    setData(prevState => {
      return {...prevState, ...newState }
    })
    if (props.onAutoSave) {
      props.onAutoSave(newState)
    }
  }
  const getInput = field => {
    const onChange = value => {
      setValue(field.name, value)
    }
    const value = data[field.name]
    if (field.type == "radio") {
      return field.options.map(option => {
        return <FormButton key={option.value} onPress={() => {
          onChange(option.value)
        }} title={option.title} style={{backgroundColor: option.value != value ? Colors.light : Colors.dark}} />
      })
    } else if (field.type == "image") {
      return <PhotoEdit content={value} onChange={onChange} single={true} />
    } else if (field.type == "boolean") {
      return <Switch value={value} onValueChange={onChange} trackColor={{true: Colors.light, false: Colors.light}} thumbColor="#888" activeThumbColor="#fff" />
    }
    const args = {}
    if (field.type == "email") {
      args.autoCapitalize = "none"
      args.keyboardType = "email-address"
    } else if (field.type == "password") {
      args.secureTextEntry = true
    } else if (field.type == "textarea") {
      args.multiline = true
      args.numberOfLines = 5
    }
    return <TextInput placeholder={field.label} value={value || ""} onChangeText={onChange} {...args} />
  }
  const submit = () => {
    const missingFields = props.fields.filter(field => field.type != "boolean" && !data[field.name])
    if (missingFields.length) {
      const errors = {}
      missingFields.forEach(field => {
        errors[field.name] = "Required"
      })
      setResponse({
        message: "Please complete all fields",
        errors
      })
      return
    }
    const onSubmit = props.onSubmit(data)
    if (onSubmit) {
      return onSubmit.then(value => {
        const success = value && value.message
        if (response.success !== success) {
          setResponse({success})
        }
        return value
      }).catch(setResponse)
    } else {
      setResponse({})
    }
  }
  return <>
    {props.fields.map((field, i) => <View key={i} style={{ marginVertical: 10 }}>
      <Text style={{fontWeight: "bold" }}>{field.label}</Text>
      {response.errors && response.errors[field.name] && <Text style={{color:"#f00"}}>{response.errors[field.name]}</Text>}
      {getInput(field, i)}
    </View>)}
    {response.success && <Text>{response.success}</Text>}
    {response.message && <FormError>{response.message}</FormError>}
    {!props.onAutoSave && <FormButton title={props.submit || "Submit"} onPress={submit} />}
  </>
}

export default Form
import React from "react"
import { Pressable } from "react-native"

import Colors from "../Colors"

import Avatar from "./Avatar"
import Icon from "./Icon"

const AvatarListItem = props => {
  const { user, icon, onPress, children } = props
  return <Pressable onPress={onPress} style={{
    marginVertical: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: Colors.veryLight
  }}>
    <Avatar user={user} size={48} />
    {children}
    <Icon symbol={icon || "rightArrow_light"} />
  </Pressable>
}

export default AvatarListItem
import React, { useContext, useState } from "react"
import { Pressable, Text, TextInput, View } from "react-native"
import { StackActions, CommonActions, useNavigation, useRoute } from "@react-navigation/native"

import Avatar from "./Avatar"
import Colors from "../Colors"
import { Styles } from "../Styles"
import { isDesktop } from "../MediaQueries"
import ProfileImage from "./ProfileImage"
import Icon from "../components/Icon"
import FormButton from "../components/FormButton"
import { AuthContext, web } from "../providers/AuthProvider"

const IconPressable = ({style, onPress, active, title}) => {
  return <Pressable onPress={onPress} style={{
    backgroundColor: Colors.light,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    ...style
  }}>
    <Icon symbol={active ? "box_filled" : "box_light"} narrow />
    <Text style={{ marginLeft: 10 }}>{title}</Text>
  </Pressable>
}

const getTabs = user => {
  const tabs = [
    {routeName: "Updates"},
    {routeName: "GospelWorkers", title: "Gospel Workers"},
    {routeName: "Partners"}
  ]
  if (!user || user.role == "gospelworker") {
    tabs.push({routeName: "Share"})
  }
  tabs.push({routeName: "Messages"})
  return tabs
}

const navigate = (navigation, name) => {
  navigation.dispatch(CommonActions.reset({
    index: 0,
    routes: [
      {name}
    ]
  }))
}

export const Header = props => {
  const { title } = props
  const navigation = useNavigation()
  const { api, user } = useContext(AuthContext)
  const [ searchResults, setSearchResults ] = useState([])
  const [ actionsOpen, setActionsOpen ] = useState(false)
  const [ searchOpen, setSearchOpen ] = useState(false)

  const routes = navigation.getState().routes
  const canGoBack = routes.length > 1

  const desktop = isDesktop()
  const desktopWeb = desktop && web
  const currentRouteName = useRoute().name
  const link = currentRouteName != "Settings"
  const baseStyles = {
    justifyContent: "center",
    flexDirection: "row",
    borderBottom: "1px "+Colors.grey+" solid"
  }
  if (!web) {
    baseStyles.paddingTop = 48
  }
  if (desktopWeb) {
    baseStyles.paddingBottom = 16
  }
  const outerStyle = {
    width: 48,
    height: 48,
    backgroundColor: "#ffd6d6",
    justifyContent: "center",
    alignItems: "center"
  }

  const actions = []
  if (user && user.role == 'gospelworker') {
    actions.push({title: "Share an Update", routeName: "EditUpdate"})
  }
  actions.push({title: "Message", routeName: "Messages"})
  if (user && user.role == 'gospelworker') {
    actions.push({title: "Add a new Partner", routeName: "PartnerAsk"})
  }
  actions.push({title: "Partner with a Gospel Worker", routeName: "PartnerEnter"})

  const modal = props.modal && canGoBack && !desktopWeb
  const rightIcons = props.rightIcons || (!modal && <>
    <Icon symbol="search_light" onPress={() => {
      setSearchOpen(true)
    }} />
    <Icon symbol="plus_light" onPress={() => {
      setActionsOpen(!actionsOpen)
    }} />
  </>)

  const profileImage = <ProfileImage user={user} size={24} />
  return <View style={baseStyles}>
    <View style={{
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: !desktopWeb && "center",
      flex: 1,
      maxWidth: 875,
      marginHorizontal: desktopWeb && 10
    }}>
      {searchOpen ? <>
        <Icon symbol="search_light" onPress={() => {
          setSearchOpen(false)
        }} />
        <View style={{
          backgroundColor: Colors.light,
          justifyContent: "center",
          flex: 1,
          marginRight: 10,
          paddingHorizontal: 16
        }}>
          <TextInput placeholder="Search" onChangeText={term => {
            api.get("search", {term}).then(setSearchResults)
          }} style={{
            height: 24
          }} />
        </View>
        {searchResults.length ? <View style={{
          position: "absolute",
          top: "100%",
          width: "100%",
          flexDirection: "column",
          borderLeft: "1px #000 solid",
          borderTop: "1px #000 solid",
          borderRight: "1px #000 solid"
        }}>
          {searchResults.map((result, i) => <Pressable key={i} style={{
            flexDirection: "row",
            alignItems: "center",
            padding: 5,
            borderBottom: "1px #000 solid",
            backgroundColor: "#fff"
          }} onPress={() => {
            setSearchOpen(false)
            if (result.id) {
              navigation.push("Update", {
                slug: result.user_slug,
                id: result.id
              })
            } else {
              navigation.push("Partners", {slug: result.user_slug})
            }
          }}>
            <Avatar link user={result} size={25} />
            <Text>{result.id ? result.content : result.user_name}</Text>
          </Pressable>)}
        </View> : null}
        <Icon symbol="cross_light"  onPress={() => {
          setSearchOpen(false)
        }} />
      </> : <>
        {canGoBack ? <Icon symbol={modal ? "cross_light" : "leftArrow_light"} onPress={() => {
          navigation.goBack()
        }} /> : (link ? <Pressable style={outerStyle} onPress={() => {
          navigation.push("Settings")
        }}>{profileImage}</Pressable> : <View style={outerStyle}>{profileImage}</View>)}
        {desktopWeb ? getTabs(user).map((tab, i) => <IconPressable key={i} onPress={() => {
          navigate(navigation, tab.routeName)
        }} active={currentRouteName == tab.routeName} title={tab.title || tab.routeName} />) : <Text style={Styles.heading}>{title || currentRouteName}</Text>}
        {rightIcons ? (desktopWeb ? rightIcons : <View style={{ flexDirection: "row" }}>
          {rightIcons}
        </View>) : <View style={{ width: 48 }} />}
        {actionsOpen && <View style={{
          position: "absolute",
          right: 0,
          top: "100%",
          alignSelf: "flex-end"
        }}>
          {actions.map((action, i) => <IconPressable key={i} onPress={() => {
            setActionsOpen(false)
            navigation.push(action.routeName)
          }} active={currentRouteName == action.routeName} title={action.title} style={{
            paddingVertical: 5
          }} />)}
        </View>}
      </>}
    </View>
  </View>
}

export const Footer = () => {
  const { user } = useContext(AuthContext)
  const currentRouteName = useRoute().name
  const navigation = useNavigation()
  return <View style={{
    marginTop: -98,
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    borderTop: "1px "+Colors.grey+" solid"
  }}>
    {getTabs(user).map((tab, i) => <Icon key={i} onPress={() => {
      navigate(navigation, tab.routeName)
    }} symbol={currentRouteName == tab.routeName ? "box_filled" : "box_light"} />)}
  </View>
}
import React from "react"
import { View } from "react-native"

import { isDesktop } from "../MediaQueries"
import { baseStyles } from "../Styles"
import { Footer } from "../components/HeaderFooter"
import { web } from "../providers/AuthProvider"

const BaseScreen = ({ children, noMobileTabs, full }) => {
  const styles = baseStyles(isDesktop(), web)
  const desktopWeb = isDesktop() && web
  const childrenStyles = {
    height: web ? "100vh" : "100%",
    overflow: "scroll",
    paddingTop: !full && styles.top,
    paddingBottom: web ? 65 : 98
  }
  if (!full) {
    if (desktopWeb) {
      childrenStyles.marginLeft = "auto"
      childrenStyles.marginRight = "auto"
      childrenStyles.width = "100%"
      childrenStyles.maxWidth = styles.width
      childrenStyles.paddingHorizontal = 10
    } else {
      childrenStyles.paddingHorizontal = 16
    }
  }
  return <>
    <View style={childrenStyles}>
      {children}
    </View>
    {!desktopWeb && !noMobileTabs && <Footer />}
  </>
}

export default BaseScreen
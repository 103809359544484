import React, { useContext, useEffect, useState } from "react"
import { Image, View } from "react-native"

import FormButton from "../components/FormButton"
import FormError from "../components/FormError"
import { web, storage_url } from "../providers/AuthProvider"

let imagePicker
if (!web) {
  imagePicker = require("react-native-image-picker")
}
import Dropzone from "react-dropzone"


const PhotoEdit = props => {
  const { content, onChange, single } = props
  const [photos, setPhotos] = useState(content ? (single ? [content] : content) : [])
  const [error, setError] = useState()
  useEffect(() => {
    const files = photos.map(photo => photo.file)
    onChange(files.length ? (single ? files[0] : files) : null)
  }, [photos])
  const uploadFiles = acceptedFiles => {
    acceptedFiles.forEach(file => {
      console.log({file})
      
      const reader = new FileReader()

      reader.onerror = e => {
        console.log({e})
        setError("An error occurred")
      }
      reader.onload = () => {
        const photo = {
          file,
          data: reader.result
        }
        setPhotos(photos ? [...photos, photo] : [photo])
      }
      reader.readAsDataURL(file)
    })
  }
  const photoInput = <>
    {error && <FormError>{error}</FormError>}
    {web ? <Dropzone accept="image/*" onDrop={acceptedFiles => {
      setError()
      uploadFiles(acceptedFiles)
    }}>
      {({getRootProps, getInputProps}) => <section>
        <View {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag and drop, or click to select</p>
        </View>
      </section>}
    </Dropzone> : <FormButton title="Add Photo" onPress={() => {
      imagePicker.launchImageLibrary({
        selectionLimit: single ? 1 : 0
      }, response => {
        setError()
        if (!response.didCancel) {
          if (response.error) {
            setError("An error occurred")
          } else {
            uploadFiles(response.assets/*.map(asset => {
              console.log(asset)
              return {path: asset.uri}
            })*/)
          }
        }
      })
    }} />}
  </>
  return <>
    {photos.map((photo, i) => <View key={i}>
      <FormButton title="Remove Photo" onPress={() => {
        setPhotos(photos.filter((photo, index) => index !== i))
      }} />
      <Image source={photo.data || storage_url(photo)} style={{
        width: 250,
        height: 250
      }} />
    </View>)}
    {(single && photos.length) ? null : photoInput}
  </>
}

export default PhotoEdit
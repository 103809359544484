import React, { useContext, useEffect, useState } from "react"
import { Image, Text, TextInput, View } from "react-native"

import BaseScreen from "../components/BaseScreen"
import { AuthContext } from "../providers/AuthProvider"
import Colors from "../Colors"
import { Styles } from "../Styles"
import FormButton from "../components/FormButton"
import ProfileImage from "../components/ProfileImage"
import ProfileActions from "../components/ProfileActions"
import ScrollList from "../components/ScrollList"
import Update from "../components/Update"

const GospelWorkerProfileScreen = props => {
  const { navigation, route } = props
  const { api, user } = useContext(AuthContext)

  const slug = route.params && route.params.slug
  let self = false
  if (user && (!slug || slug === user.slug)) {
    self = true
  }
  const [ profileUser, setProfileUser ] = useState()
  const [ connected, setConnected ] = useState(false)
  const [ error, setError ] = useState('loading')
  useEffect(() => {
    if (!user) {
      return
    }
    if (self) {
      setProfileUser(user)
    } else {
      if (slug) {
        api.get("gospelworker/"+slug).then(otherUser => {
          otherUser.slug = slug
          otherUser.role = "gospelworker"
          setProfileUser(otherUser)
          setConnected(otherUser.connected || false)
        }).catch(() => {
          setError('not found')
        })
      }
    }
  }, [self, slug, user])
  useEffect(() => {
    if (!self && profileUser && profileUser.name) {
      navigation.setOptions({
        title: profileUser.name
      })
    }
  }, [profileUser])

  const details = []
  if (profileUser) {
    if (profileUser.role) {
      details.push({
        gospelworker: 'Gospel Worker',
        partner: 'Partner',
        admin: 'Admin'
      }[profileUser.role])
    }
    if (profileUser.location) {
      details.push(profileUser.location)
    }
  }
  const top = <>
    <View style={{ textAlign: 'center', alignItems: 'center' }}>
      <ProfileImage user={profileUser} size={250} />
      <Text style={Styles.headingWithPadding}>{profileUser && profileUser.name}</Text>
      <Text>{details.join(', ')}</Text>
    </View>

    {!self && <ProfileActions profileUser={profileUser} action="give" />}
  </>
  return <BaseScreen>
    {profileUser ? <ScrollList route={"updates/"+(slug || user.slug)} autorefresh="top" itemsSort={(a, b) => new Date(b.created_at) - new Date(a.created_at)} header={top} renderItem={update => {
      return <Update content={update} user={profileUser} />
    }} /> : <Text>{error}</Text>}
  </BaseScreen>
}

export default GospelWorkerProfileScreen